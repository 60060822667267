import '../../index.css';
import moment from 'moment';
import Bets from '../bets/betsTab';
import { motion } from "framer-motion";
import { getCaller } from '../../services/api';
import { AiOutlineClose } from "react-icons/ai";
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';

const RejectedBets = () => {
    const [searchUserID, setSearchUserID] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerms, setSearchTerms] = useState({});
    const [userId, setUserId] = useState(null);

    const getRejectedBetsData = async (user_id = '') => {
        const response = await getCaller(`api/v1/bets/rejectionBetList?user_id=${user_id}`);
        if (response.success) {
            setFilteredData(response.data);
        }
    };

    useEffect(() => {
        getRejectedBetsData();
    }, []);

    const handleInputChange = async (e) => {
        const value = e.target.value.trim();
        setSearchUserID(value);

        if(value === ''){
            await getRejectedBetsData(value);
        }
    };

    const handleUserIdSearch = async() => {
        await getRejectedBetsData(searchUserID);
    };

    const openModal = async (userId) => {
        try {
            setUserId(userId);
            const response = await getCaller(`api/v1/bets/rejectionUserBetList/${userId}`);
            setSelectedUser( response.data || []);
        } catch (error) {
            console.log("Failed to fetch user-specific rejected bets.", error);
        }
    };

    const handleOnClose = async () => {
        setSelectedUser(null);
    };

    const handleSearch = async () => {
        const searchArr = Object.values(searchTerms);        
        const searchFields = searchArr.filter(Boolean);

        const response = await getCaller(`api/v1/bets/rejectionUserBetList/${userId}`);
        const userBetList = response.data || [];

        const updatedList =  userBetList.filter((item) => {
            return searchFields.every((term) =>
            Object.values(item).some((value) =>
                String(value).toLowerCase().includes(term.toLowerCase())
             )
            );
        });
        setSelectedUser(updatedList);
    };

    const onChangeSearch = async (value, id) => {        
        setSearchTerms((prev) => ({ ...prev, [id]: value.trim()}));
    };

    return (
        <>
            <Bets />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="result-sport-container">
                    <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px', alignItems: 'flex-end' }}>
                        <div className="select-container" style={{ maxWidth: '280px' }}>
                            <label htmlFor="user">User ID</label>
                            <input type="search" autoComplete="off" className="result-input" name="user" placeholder="User ID" value={searchUserID} onChange={handleInputChange} />
                        </div>

                        <div className="wrapper-content">
                            <div className="custom-btn" style={{ marginTop: '20px' }}>
                                <Button className={'submit'} name={'Search'} type="button" onClick={handleUserIdSearch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="table-border" style={{ padding: '0px' }}>
                    <div className="wrapper-body">
                        <div className="wrapper-content" style={{ width: "100%" }}>
                            <h2>List of Rejected Bets</h2>
                        </div>
                    </div>

                    {filteredData.length > 0 ? (
                        <table className="odds-gap-table">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Rejected Bet Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((data) => (
                                    <tr key={data.user_id}>
                                        <td><button className="link-button" onClick={() => openModal(data.user_id)}>{data.user_id}</button></td>
                                        <td>{data.bets_count}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <ErrorBoundary text='Rejected Bets'/>
                    )}
                </div>
            </motion.div>

            {selectedUser && (
                <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                    <div className="modal-content" style={{ minHeight: '80vh', position: 'relative', display: 'flex', flexDirection: 'column', minWidth: 'fit-content'}}>
                        <div className="wrapper-body">
                            <div className="modal-header">
                                <h2>Rejected Bets for {userId}</h2>
                                <div className="ai-close closeBtn-modal"  onClick={handleOnClose}>
                                    <AiOutlineClose />
                                </div>
                            </div>
                        </div>

                        <div className='flex-wrap-between'>
                            <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-1')} className='result-input' style={{ width: '20%' }} />
                            <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-2')} className='result-input' style={{ width: '20%' }} />
                            <input type="search" autoComplete="off" placeholder='Search' onChange={(e) => onChangeSearch(e.target.value, 'search-3')} className='result-input' style={{ width: '20%' }} />
                            <div className="custom-btn" >
                                <Button className='submit' type='button' name='Search' onClick={handleSearch}/>
                            </div>
                        </div>
                        
                        <div className='table-responsive'>
                            <table className="odds-gap-table table-border-1">
                                <thead>
                                    <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Event</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Market</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Selection</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Stake (INR)</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Current Odds</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Screen Odds</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Requested Odds</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Bet Time</th>
                                        <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>IP</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {selectedUser.length > 0 ? (
                                        selectedUser.map((bet, index) => (
                                            <tr key={index} className={bet.selection_type + ' custom-row'}>
                                                <td>{bet.event_name}</td>
                                                <td>{bet.market_name}</td>
                                                <td>{bet.selection_name}</td>
                                                <td>{bet.stake}</td>
                                                <td>{bet.current_odds}</td>
                                                <td>{bet.screen_odds}</td>
                                                <td>{bet.requested_odds}</td>
                                                <td>{ moment.utc(bet.bet_time).local().format('DD-MM-YYYY HH:mm:ss')}</td>
                                                <td>{bet.ip}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr><td colSpan="8"><ErrorBoundary /></td></tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RejectedBets;
