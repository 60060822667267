import React, { useState, useEffect } from 'react'
import { getCaller, postCaller } from '../../services/api';
import '../addEvent/addevent.css'
import 'react-dates/initialize';
import { motion } from "framer-motion";
import 'react-dates/lib/css/_datepicker.css';
import Select from 'react-select';
import { selectStyles } from '../../services/utils'
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import '../surveillance/surveillance.css'
import SurveillanceBets from './surveillanceBets';
import Button from '../../component/button/Button';
import ErrorBoundary from '../../error/ErrorBoundary';
import Bets from '../bets/betsTab';
import Paginations from '../../component/pagination/Paginations';
import { showConfirmationDialog, showToast } from '../../utilities/toastHelper';

const Surveillance = () => {
    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState("");
    const [operator, setOperator] = useState("");
    const [selectedOptionSport, setSelectedOptionSport] = useState(null);
    const [selectedOptionOperator, setSelectedOptionOperator] = useState(null);
    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [marketList, setMarketList] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [marketType, setMarketType] = useState(null);
    const [matchID, setMatchID] = useState(null);
    const [eventID, setEventID] = useState(null);
    const [userList, setUserList] = useState([]);
    const [isExpanded, setIsExpanded] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [selected, setSelected] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [searchUserId, setSearchUserId] = useState('');
    const [total, setTotal] = useState(0);
    const [userBetList, setUserBetList] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingEvent, setLoadingEvent] = useState(false);
    const [surveillance, setSurveillance] = useState([{ value: 6, label: 'Surveillance 6' }]);
    const [ipAddress, setIpAddress] = useState("");
    const [perPageEvents, setPerPageEvents] = useState(200);
    const [totalPageEvents, setTotalPageEvents] = useState(0);
    const [currentPageEvents, setCurrentPageEvents] = useState(1);
    const [perPageMarkets, setPerPageMarkets] = useState(200);
    const [totalPageMarkets, setTotalPageMarkets] = useState(0);
    const [currentPageMarkets, setCurrentPageMarkets] = useState(1);
    const [perPageUsers, setPerPageUsers] = useState(200);
    const [totalPageUsers, setTotalPageUsers] = useState(0);
    const [currentPageUsers, setCurrentPageUsers] = useState(1);

    const operatorList = [
        { value: 'clickbet', label: 'CLICKBET' },
        { value: 'clickbetold', label: 'CLICKBET OLD' },
        // { value: 'victory', label: 'Victory' },
    ];

    const surveillanceList = [
        { value: 1, label: 'Surveillance 1' },
        { value: 2, label: 'Surveillance 2' },
        { value: 3, label: 'Surveillance 3' },
        { value: 4, label: 'Surveillance 4' },
        { value: 5, label: 'Surveillance 5' },
        { value: 6, label: 'Surveillance 6' },
    ];

    useEffect(() => {
        async function getSport() {
            const sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data.filter((el) => el.is_custom === 0))
        }
        getSport()
    }, [])

    const markCheck = (event) => {
        let updatedList = [...selected];

        if (event.target.checked) {
            updatedList = [...updatedList, parseInt(event.target.value)];
        } else {
            updatedList.splice(updatedList.indexOf(parseInt(event.target.value)), 1);
            if (allSelected) setAllSelected(!allSelected);
        }

        setSelected(updatedList);
    }

    const markAllCheck = () => {
        const newStatus = !allSelected;
        setAllSelected(newStatus);
        newStatus ? setSelected(userList.map(user => parseInt(user.user))) : setSelected([]);
    }

    async function handleChangeSport(event) {
        setOperator('');
        setSelectedOptionOperator(null);
        setEventList([]);
        setTotalPageEvents(0);
        setTotal(0);
        setSelectedEvent(null);
        if (event) {
            setSport(event.value);
            setSelectedOptionSport(event);
        } else {
            setSport('');
            setSelectedOptionSport(null);
            return;
        }
    }

    async function handleChangeOperator(event) {
        const initialPerPage = 200;
        setPerPageEvents(initialPerPage);
        if (event) {
            setOperator(event.value);
            setSelectedOptionOperator(event);
            setSelectedEvent(null);
            await getEvents(sport, event.value, 1, initialPerPage);
        } else {
            setOperator('');
            setSelectedOptionOperator(null);
            setEventList([]);
            setTotalPageEvents(0);
            setTotal(0);
            setSelectedEvent(null);
            return;
        }
    }

    async function getEvents(sportId, operator, page = 1, limit = perPageEvents) {
        try {
            if (sportId) {
                setLoadingEvent(true);
                const eventResponse = await getCaller(`api/v1/surveillance/fetch-events/${operator}?sport_id=${sportId}&page=${page}&limit=${limit}&uid=${searchUserId}`);
                const fetchedData = eventResponse?.data || [];
                setEventList(fetchedData);

                const { per_page, pages, page: currentPage, total } = eventResponse?.pagination;
                setTotal(total);
                setPerPageEvents(per_page);
                setTotalPageEvents(pages);
                setCurrentPageEvents(currentPage);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingEvent(false);
        }
    }

    async function toggleEventDetails(event) {
        const initialPerPageMarket = 10;
        const isSelectedEvent = selectedEvent === event.match_id;
        setMarketList([]);
        setIsExpanded(null);
        setSelectedEvent(isSelectedEvent ? null : event.match_id);
        setTotalPageMarkets(0);
        setCurrentPageMarkets(1);
        setTotalPageUsers(0);
        setCurrentPageUsers(1);
        setSurveillance([{ value: 6, label: 'Surveillance 6' }]);
        setPerPageMarkets(initialPerPageMarket);

        if (!isSelectedEvent) {
            setMatchID(event.id);
            setEventID(event.match_id);
            await getMarkets(event.match_id, 1, initialPerPageMarket);
        }
    };

    async function getMarkets(eventId, page = 1, limit = perPageMarkets) {
        try {
            setLoading(true);
            const marketResponse = await getCaller(`api/v1/surveillance/fetch-markets/${eventId}/${operator}?page=${page}&limit=${limit}`);
            setMarketList(marketResponse?.data);
            const { per_page, pages, page: currentPage } = marketResponse?.pagination;
            setPerPageMarkets(per_page);
            setTotalPageMarkets(pages);
            setCurrentPageMarkets(currentPage);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    async function toggleMarketDetails(eventId, marketId, marketType) {
        const initialPerPageUser = 10;
        setTotalPageUsers(0);
        setCurrentPageUsers(1);
        setCurrentPageUsers(initialPerPageUser)

        const surveillanceData = [{ value: 6, label: 'Surveillance 6' }];
        setSurveillance(surveillanceData);

        if (isExpanded === marketId) {
            setIsExpanded(null);
        } else {
            setSelectedMarket(marketId);
            setMarketType(marketType);
            await getUsers({ eventId, marketId, selectedMarketType: marketType, limit: initialPerPageUser });
            setIsExpanded(marketId);
        }
    };

    async function getUsers({ eventId = eventID, marketId = selectedMarket, selectedMarketType = marketType, page = 1, type = '', ip = ipAddress, limit = perPageUsers }) {
        try {
            type = type || surveillance.map(s => s.value);
            const userResponse = await getCaller(`api/v1/surveillance/fetch-users/${eventId}/${selectedMarketType}/${marketId}/${operator}?page=${page}&limit=${limit}&ip=${ip}&stype=${type.join(',')}`);
            setUserList(userResponse?.data || []);
            const { per_page, pages, page: currentPage } = userResponse?.pagination;
            setPerPageUsers(per_page);
            setTotalPageUsers(pages);
            setCurrentPageUsers(currentPage);
        } catch (err) {
            console.error(err);
        }
    }

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate.replace(',', '');
    };

    async function handleViewBet(userId) {
        const userDetail = userList.find(user => user.uid == userId);
        setUserBetList(userDetail.bets || []);
        setIsChanged(true);
        setUserId(userId);
        setModalOpen(true);
    };

    const handleInputChange = (e) => {
        setSearchUserId(e.target.value);
    };

    const handlePageChange = async (page) => {
        setCurrentPageEvents(page);
        await getEvents(sport, operator, page);
    };

    const handleMarketPageChange = async (page) => {
        setIsExpanded(null);
        setCurrentPageMarkets(page);
        await getMarkets(eventID, page);
    };

    const handleUserPageChange = async (page) => {
        setCurrentPageUsers(page);
        await getUsers({ page });
    };

    async function handleUnlockUser(userId) {
        await showConfirmationDialog(`You want to Unlock this user?`)
            .then(async (result) => {
                if (result.isConfirmed) {
                    const userResponse = await postCaller(`api/v1/surveillance/unlock-users/${operator}`, { userId });
                    showToast(userResponse?.data?.message, userResponse?.data?.is_error ? true : false);
                }
            }).catch(err => console.error(err));
    };

    const handleSurveillanceFilter = async (selectedOptions) => {
        setSurveillance(selectedOptions);
        const surveillanceIds = selectedOptions.map(s => s.value);
        await getUsers({ type: surveillanceIds });
    };

    const handleIPChange = async (e) => {
        const value = e.target.value.trim();
        setIpAddress(value);
        if (!value) await getUsers({ ip: value });
    };

    const handleIPBlur = async () => {
        await getUsers({});
    };

    return (
        <>
            <Bets />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                {/* <div className="wrapper-head">
                    <h3> Surveillance </h3>
                </div> */}
                <div className="result-sport-container">
                    <div className="result-sport-content" style={{ 'justifyContent': 'flex-start', gap: '15px', 'alignItems': 'flex-end' }}>
                        <div className="select-container" style={{ 'maxWidth': '280px' }}>
                            <label htmlFor="sport"> Select Sport </label>
                            <Select
                                value={selectedOptionSport}
                                onChange={handleChangeSport}
                                options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                styles={selectStyles}
                                placeholder="Select Sport"
                                isClearable={true}
                            />
                        </div>
                        <div className="select-container" style={{ 'maxWidth': '280px' }}>
                            <label htmlFor="sport"> Select Operator </label>
                            <Select
                                value={selectedOptionOperator}
                                onChange={handleChangeOperator}
                                options={sport ? operatorList : []}
                                styles={selectStyles}
                                placeholder="Select Operator"
                                isClearable={true}
                            />
                        </div>
                        <div className="select-container" style={{ 'maxWidth': '280px' }}>
                            <label htmlFor="date">User ID</label>
                            <input type="search" autoComplete="off" className="result-input" name="user" placeholder="User ID" value={searchUserId} onChange={handleInputChange} />
                        </div>
                        <div className="wrapper-content">
                            <div className="custom-btn" style={{ marginTop: '20px' }}>
                                <Button className={'submit'} name={'Search'} type='button' disabled={!sport || !operator} onClick={async () => { await getEvents(sport, operator) }} />
                            </div>
                        </div>
                        <h5 style={{ 'margin': '10px 0' }}> {"Total Match : " + total}</h5>
                    </div>
                </div>
                {loadingEvent ?
                    <div className="loader-wrapper">
                        <div className='loader'>
                        </div>
                    </div> :
                    eventList?.length > 0 ? (
                        <div className="league-setting-container" initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                            <div className="market-section">
                                <>
                                    {eventList.map((event, index) => (
                                        <div key={index} className="market-section" style={{ borderBottom: "solid", padding: "8px" }}>
                                            <button className={`accordion ${selectedEvent === event.match_id ? 'active' : 'inactive'}`} onClick={() => toggleEventDetails(event)} style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                                {
                                                    selectedEvent === event.match_id ? (
                                                        <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                                    ) : (
                                                        <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                                    )
                                                }&nbsp;
                                                <p>{event.event_name} - {formatDate(event.event_date)} {'(' + event.total_markets + ')'}</p>
                                            </button>
                                            {loading ? <div className="loader-wrapper">
                                                <div className='loader'>
                                                </div>
                                            </div> :
                                                selectedEvent === event.match_id && (
                                                    marketList?.length > 0 ? (
                                                        <>
                                                            {marketList.map((market, index) => (
                                                                <div key={market.market_id} className="market-section" style={{ borderBottom: "groove", padding: "8px" }}>
                                                                    <div className="container" style={{ alignItems: "flex-start" }} >
                                                                        <div className="left">
                                                                            <label>
                                                                                {/* <input type="checkbox" checked={allSelected} onChange={markAllCheck} /> */}
                                                                                <span className="match-odds"> {market.market_name + '(' + market.user_count + ')'} </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="right surveil_wrap">
                                                                            <div className=' selectWrap-main'>
                                                                                {(isExpanded && isExpanded === market.market_id) ?
                                                                                    <>
                                                                                        <Select
                                                                                            value={surveillance}
                                                                                            onChange={handleSurveillanceFilter}
                                                                                            options={surveillanceList}
                                                                                            placeholder="Select Surveillance"
                                                                                            isMulti
                                                                                            isClearable={true}
                                                                                        />
                                                                                        <input type="search" autoComplete="off" className='result-input' value={ipAddress} onChange={handleIPChange} onBlur={handleIPBlur} name="ip_address" placeholder="IP Address" />
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="right">
                                                                            <button className={`btn ${!isExpanded || isExpanded !== market.market_id ? 'view-user' : 'close-user'}`} onClick={() => toggleMarketDetails(event.match_id, market.market_id, market.market_type)}>{!isExpanded || isExpanded !== market.market_id ? 'View User' : 'Close User'}</button>
                                                                        </div>
                                                                    </div>
                                                                    {isExpanded === market.market_id && (
                                                                        <div>
                                                                            {/* <div className="table-container"> */}
                                                                                <table className="custom-table" >
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>User Name</th>
                                                                                            <th>Surveillance Name</th>
                                                                                            <th style={{ textAlign: "center" }}>Action</th>
                                                                                            <th style={{ textAlign: "center" }}>View</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {userList.map((row) => (
                                                                                            <tr key={row.uid}>
                                                                                                <td>
                                                                                                    {/* <input type="checkbox" checked={selected.includes(parseInt(row.user))} value={row.user} onChange={markCheck} /> */}
                                                                                                    {row.uid}
                                                                                                </td>
                                                                                                <td>{row.type}</td>
                                                                                                <td style={{ textAlign: "center" }}>
                                                                                                    <button className="unlock-button" onClick={() => handleUnlockUser(row.user)}>Unlock</button>
                                                                                                </td>
                                                                                                <td style={{ textAlign: "center" }}>
                                                                                                    <button className="view-bet-button" onClick={() => handleViewBet(row.uid)}> View Bet</button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            {/* </div> */}
                                                                            <Paginations page={currentPageUsers} perPage={perPageUsers} setPage={handleUserPageChange} setPerPage={setPerPageUsers} maximo={totalPageUsers} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))
                                                            }
                                                            <Paginations page={currentPageMarkets} perPage={perPageMarkets} setPage={handleMarketPageChange} setPerPage={setPerPageMarkets} maximo={totalPageMarkets} />
                                                        </>
                                                    ) : <ErrorBoundary />
                                                )}
                                        </div>
                                    ))
                                    }
                                    <Paginations page={currentPageEvents} perPage={perPageEvents} setPage={handlePageChange} setPerPage={setPerPageEvents} maximo={totalPageEvents} />
                                </>
                            </div>
                        </div>
                    ) : <ErrorBoundary />
                }
            </motion.div>
            <SurveillanceBets isModalOpen={isModalOpen} setModalOpen={setModalOpen} sportId={sport} userId={userId} marketId={selectedMarket} marketType={marketType} matchId={matchID} operator={operator} isChanged={isChanged} setIsChanged={setIsChanged} userBetList={userBetList} />
        </>
    )
}

export default Surveillance

