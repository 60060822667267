import React, { useEffect, useState } from 'react'
import Pagination from '../../component/pagination/Pagination'
import Button from '../../component/button/Button'
import { postCaller } from '../../services/api'
import '../result/result.css'
import { BsChevronDown, BsChevronRight } from "react-icons/bs"
import moment from 'moment';
import ServerToBrowserTime from './serverToBrowser';
import getLocalData from '../../utilities/LocalStorageData.utilities'
import { showToast, showConfirmationDialog} from '../../utilities/toastHelper';

const MatchResultTable = ({ resultData, setResultData,allResult,totalPage }) => {
  const [rowOpen, setRowOpen] = useState(null);
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(resultData?.paginationData?.currentPage || 1);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [scope, setScope] = useState({})

  useEffect(() => {
    const { result, rollback } = getLocalData('scope');
    const userType = getLocalData('user_role');
    setScope({ result, rollback, userType });
  }, []);

  let completeData = resultData?.filter(() => true);

 const handlePageChange = async(page) => {
    setCurrentPage(page);
    await allResult(page)
  };

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null);
    } else {
      setRowOpen(index);
      setShowRollbackStatus(false);
      setShowResultStatus(false);
    }
  };

  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus)
    setShowRollbackStatus(false)
  }

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus)
    setShowResultStatus(false)
  }
  
  const handleRedeclare = async (id, operator) => {
    let data = {
      id: id,
      operator: operator
    };

    const formatOperator = operator.replace(/([A-Z]+)([A-Z][a-z]+)/, '$1 $2').toLowerCase().replace(/(^\w|\s\w)/g, char => char.toUpperCase());

    await showConfirmationDialog(`You want to redeclare result for ${formatOperator} market?`)
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await postCaller(`api/v1/worli/results/redeclare`, data);
          if (res.success) {
            showToast('The result was redeclared successfully!');
          } else {
            showToast('Failed to redeclare the result. Please try again.', true);
          }
        }
      });
  }

  const handleRollBackRedeclare = async (id, operator) => {
    let data = {
      id: id,
      operator: operator
    };

    const formatOperator = operator.replace(/([A-Z]+)([A-Z][a-z]+)/, '$1 $2').toLowerCase().replace(/(^\w|\s\w)/g, char => char.toUpperCase());

    await showConfirmationDialog(`You want to rollback result for ${formatOperator} market?`)
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await postCaller(`api/v1/worli/results/rollback/redeclare`, data);
          if (res.success) {
            showToast('The result was rollbacked successfully!');
          } else {
            showToast('Failed to rollback the result. Please try again.', true);
          }
        }
      });
  };

  const declareResult = async (id, operator) => {
    let data = {
      id: id,
      operator: operator
    };

    const formatOperator = operator.replace(/([A-Z]+)([A-Z][a-z]+)/, '$1 $2').toLowerCase().replace(/(^\w|\s\w)/g, char => char.toUpperCase());

    await showConfirmationDialog(`You want to declare result for ${formatOperator} market?`)
      .then(async (result) => {
        if (result.isConfirmed) {
          const res = await postCaller(`api/v1/worli/declareResult`, data);
          if (res) {
            showToast('The result was declared successfully!');
            setDisabledButtons((prevDisabled) => [...prevDisabled, operator]);
          } else {
            showToast('Failed to declare the result. Please try again.', true)
          }
        }
      });
  };

  const handleRollBack = async (e) => {
    const resultID = e.market_id;

    await showConfirmationDialog('You want to rollback the result?')
      .then(async (result) => {
        if (result.isConfirmed) {
          const response = await postCaller(`api/v1/worli/matches/rollback/${resultID}`)
          if (response.success === true) {
            const id = response.data.resultId;
            showToast("Result has been rollbacked successfully.");
            setResultData(resultData.map(e => e.id !== id ? e : { ...e, is_rollback: 1 }));
          } else {
            showToast(response.message, true);

          }
        }
      })
  }
    
  return (
    <>
      <div className="table-border" >
        <div className="wrapper-body">
          <div className="wrapper-content-1">
          </div>
        </div>
        <div style={{ overflowX: "auto" }} >
          <table width="100%" >
            <thead>
              <tr className='custom-row' >
                <th style={{position:"relative", left:"15px"}} > # </th>
                <th><div style={{ width: "60px" }}> Market Title</div> </th>
                <th><div> Market Time </div></th>
                <th><div> Market Type </div></th>
                <th><div> Result </div></th>
                <th><div style={{ width: "105px" }}> Result Time  </div></th>
                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <th> <div> ROLLBACK </div> </th>
                }
              </tr>
            </thead>

            <tbody>
              {
                completeData?.length > 0 ?
                  completeData?.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className='custom-row' key={i} onClick={() => toggleRow(i)}>
                         <td><div className='result_status'>
                          {rowOpen === i ? (
                            <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                          ) : (
                            <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                          )}
                          {e?.id ?? "Auto"}
                        </div> </td>  
                        <td > {e?.market_title} </td>
                        <td > 
                          <ServerToBrowserTime serverTime={e.market_time} /> 
                          </td>
                        <td style={{ textTransform: "capitalize" }}> {e.market_type} </td>
                        <td > <div >{e.result} </div> </td>
                        <td > <div ><ServerToBrowserTime serverTime={e.result_time} />
                        </div> </td>
                        {
                        (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <td >
                          <div> <Button className = 'submit' type = 'button' name = 'RollBack'
                            onClick = {() => handleRollBack(e)} disabled = {(scope?.userType ==='manager' && !scope?.rollback?.add) || e.is_rollback} /> </div>
                          </td>
                        }
                      </tr>
                      {
                        rowOpen === i && (
                          <>
                            <tr className='custom-row' onClick={toggleResultStatus}>
                              <td colSpan={8}>
                                <div className='result_status'>&nbsp; &nbsp;
                                  {showResultStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Result Status
                                </div>
                              </td>
                            </tr>

                            {e.result_status && showResultStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                          <th>Declare Result</th>
                                          <th>Redeclare Result</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime === '-') ? data.startTime:moment.unix(data.startTime).format('YYYY-MM-DD HH:mm') }</td>
                                            <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                            <td>
                                              <button className='basic-button submit' style={{textWrap:"nowrap"}} 
                                                disabled={(scope?.userType==='manager' && !scope?.result?.add) || disabledButtons.includes(market) || (e.operators_list ? JSON.parse(e.operators_list).includes(market) : false) || e.is_rollback}
                                                onClick={() => declareResult(e.id, market)}>Declare Result
                                              </button>
                                            </td>
                                            <td className='custom-row'>
                                              <button className='basic-button submit' style={{textWrap:"nowrap", width:"200px"}} disabled={(scope?.userType === 'manager' && !scope?.result?.add) || ((!(e.operators_list ? JSON.parse(e.operators_list).includes(market) : true)) && !disabledButtons.includes(market)) || e.is_rollback} onClick={() => handleRedeclare(e.id, market)}> Redeclare Result </button>
                                            </td>
                                          </tr>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}

                          { e.is_rollback ? (
                            <tr className='custom-row' onClick={toggleRollbackStatus}>
                              <td colSpan={8}>
                                <div className='result_status'> &nbsp; &nbsp;
                                  {showRollbackStatus ? (
                                    <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  ) : (
                                    <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                  )}
                                  Rollback Status
                                </div>
                              </td>
                            </tr>
                            ) : "" }
                            {e.rollback_status && showRollbackStatus && (
                              <tr className='custom-row'>
                                <td colSpan={9}>
                                  <div className='result_status'>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Market</th>
                                          <th>Status</th>
                                          <th>Start Time</th>
                                          <th>End Time</th>
                                          <th>Process Count</th>
                                          <th>User Count</th>
                                          <th>Bet Count</th>
                                          <th>End Point</th>
                                          <th>RollBack</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                          <tr key={index}>
                                            <td>{market}</td>
                                            <td>{data.status}</td>
                                            <td>{(data.startTime == '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{(data.endTime == '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td>
                                            <td>{data.processCount}</td>
                                            <td>{data.userCount}</td>
                                            <td>{data.betCount}</td>
                                            <td>{data.endpoint}</td>
                                            <td className='custom-row'>
                                              <button className='basic-button submit' style={{textWrap:"nowrap", width:"200px"}} disabled={(scope?.userType==='manager' && !scope?.rollback?.add)} onClick={() => handleRollBackRedeclare(e.id, market)}> RollBack </button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        )
                      }

                    </React.Fragment>
                  )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      {/* <Pagination page={page} setPage={setPage} setPerPage={setPerPage} maximo={maximo} /> */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPage}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default MatchResultTable