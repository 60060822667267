
import React from 'react';
import '../pagination/page.css'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  // const [input, setInput] = useState (1);
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className='custom-page'>
      <div className='page-container' >
      <button to="#" onClick={handlePrevious} disabled={currentPage === 1|| currentPage < 1}>
        Prev
      </button>
      
         <input name="page" autoComplete="off" className="custom-input"
          value ={currentPage} readOnly
        />
       <button to="#" onClick={handleNext} disabled={currentPage === Math.ceil(totalPages) || currentPage > Math.ceil(totalPages)}>
        Next
      </button>
    </div>
    </div>
   
  );

};
export default Pagination

