import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../result/result.css'

const Bets = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const bets = [
    { name: "Sport Bets", route: "/bets" },
    { name: "Worli Matka Bets", route: "/bets/worli-matka-bets" },
    { name: "Racing Bets", route: "" },
    { name: "Surveillance", route: "/bets/surveillance" },
    { name: "Rejected Bets", route: "/bets/rejectedBets" }
  ]

  return (
    <div className="wrapper" >
      <ul className="tab-container">
        {
          bets.map((el, i) => (
            <li key={i} className={`tab-box ${location.pathname === el.route ? 'active-result-tab' : null}`} onClick={() => navigate(el.route)} >
              {el.name}
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default Bets;