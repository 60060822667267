import React from 'react';
import { MdSave } from 'react-icons/md';
import { updateCaller } from '../../services/api';
import '../manageOutsideMarket/manageskymarket.css';
import { handleKeyDown } from '../../utilities/handleKeyDown';
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { showConfirmationDialog, showToast } from '../../utilities/toastHelper';

const ManageSkyFancyTable = ({ getFancyList, setGetFancyList }) => {
  const handleChange = (fieldName, index, value) => {
    let newFormValues = JSON.parse(JSON.stringify(getFancyList));
    newFormValues.data[index][fieldName] = value;
    newFormValues.data[index] = { ...newFormValues.data[index] };
    setGetFancyList(newFormValues);
  };

  const handleToggle = (fieldName, index, eventID, fancyID) => {
    const updatedFancyList = [...getFancyList.data];
    updatedFancyList[index][fieldName] = updatedFancyList[index][fieldName] === 0 ? 1 : 0;
    updatedFancyList[index] = { ...updatedFancyList[index] };
    setGetFancyList({ ...getFancyList, data: updatedFancyList });
  };

  const handleUpdateFancy = async (eventID, fancyID, i) => {
    const fancy = JSON.parse(JSON.stringify(getFancyList.data[i]));

    const response = await updateCaller(`api/v1/fancies/${eventID}/${fancyID}`, fancy);

    if (response && response.success) {
      showToast(response.message, false);
      const updatedList = JSON.parse(JSON.stringify(getFancyList));
      setGetFancyList(updatedList);
    }
  }

  const setBetDelayToAllFancy = async (value) => {
    try {
      const eventID = getFancyList?.data[0]?.eventID;
      await showConfirmationDialog(`You are about to update bet delay for all the fancies of this event.`)
      .then(async (result) => {
        if (result.isConfirmed) {
          const response = await updateCaller(`api/v1/fancies/${eventID}/betDelay/${value}`);
          if (response.success) {
            showToast(response.message, false);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="sky-fancy-market" style={{ overflowX: "auto" }}>
        <div>
          <div className="sky-fancy-head">
            <p>Fancy Market</p>
            {/* <p className='sky-fancy-event'> {getFancyList?.en} </p> */}
          </div>
          <table width="100%" className='sky-fancy-table'>
            <thead>
              <tr id='fancy-head-coloumn'>
                <th> SELECTION </th>
                <th> MIN STAKE </th>
                <th> MAX STAK</th>
                <th> MAX MARKET LIMIT </th>
                <th> BET DELAY </th>
                {/* <th>COMMISSION</th> */}
                <th> SUSPEND </th>
                <th> ACTIVE </th>
                <th> ACTIONS </th>
              </tr>
            </thead>

            <tbody>
              {
                getFancyList?.data?.length ?
                  <tr className='sky-fancy-row' key={"customRow"}>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td>
                      <input type="number" className='sky-fancy-input' autoComplete="off" placeholder='Bet Delay' name="bet_delay" onKeyDown={handleKeyDown} onBlur={(event) => setBetDelayToAllFancy(event.target.value)}/>
                    </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                : null
              }
              {
                getFancyList?.data?.length > 0 ? getFancyList?.data?.map((el, i) => (
                  <tr className='sky-fancy-row' key={i}>
                    <td>
                      <input type="text" className='fancy-stake-input-1' autoComplete="off" placeholder="Fancy Selection Name" name="market_name" value={el.market_name || ""} onChange={(event) => handleChange("market_name", i, event.target.value)} disabled={true} /> <br />
                      
                      <input type="text" className='fancy-stake-input-1' autoComplete="off" placeholder="Message" name="msg" value={el.msg || ""} onChange={(event) => handleChange("msg", i, event.target.value)}/> <br />
                    </td>

                    <td>
                      <input type="number" className='sky-fancy-input' autoComplete="off" placeholder="Min Stake" name="min_stake" onKeyDown={handleKeyDown} value={el?.min_stake||""} pattern="[0-9]*" onChange={(event) => handleChange("min_stake", i, event.target.value || 0)}/>
                    </td>

                    <td >
                      <input type="number" className='sky-fancy-input' autoComplete="off" placeholder='Max Stake' name="max_stake" onKeyDown={handleKeyDown} pattern="[0-9]*" value={el?.max_stake||""} onChange={(event) => handleChange("max_stake", i, event.target.value || 0)}/>
                    </td>

                    <td>
                      <input type="number" className='sky-fancy-input' autoComplete="off" placeholder='Max Market Limit' name="yes_outcome" onKeyDown={handleKeyDown} pattern="[0-9]*" onChange={(event) => handleChange("max_market_limit", i, event.target.value || 0)} value={el?.max_market_limit || ""}/>
                    </td>

                    <td>
                      <input type="number" className='sky-fancy-input' autoComplete="off" placeholder='Bet Delay' name="bet_delay" onKeyDown={handleKeyDown} onChange={(event) => handleChange("bet_delay", i, event.target.value || 0)} value={el?.bet_delay || ""}/>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <ToogleButton onChange={() => handleToggle("is_sus", i, el.eventID, el.fancyID)} defaultChecked={el.is_sus !== 0}/>
                      </div>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <ToogleButton onChange={() => handleToggle("is_active", i, el.eventID, el.fancyID)} defaultChecked={el.is_active !== 0}/>
                      </div>
                    </td>

                    <td>
                      <div className="toggle-password">
                        <div className='actions_tab'>
                          <div className='fancy-submit' >
                            <MdSave className="icon"
                              onClick={() => handleUpdateFancy(el.eventID, el.fancyID, i)} tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                                  e.preventDefault(); handleUpdateFancy(el.eventID, el.fancyID, i)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
};

export default ManageSkyFancyTable;