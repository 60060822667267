import React from "react";
 
const BigModal = ({ isOpen, onClose, children, style={}, style1={} }) => {
    if (!isOpen) return null;
 
    return (
        <div
            onClick={onClose}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex:'1000',
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...style1,
                overflow: "hidden"
            }}
        >
            <div
                style={{
                    background: "white",
                    margin: "auto",
                    padding: "2%",
                    // border: "2px solid #000",
                    borderRadius: "10px",
                    boxShadow: "2px solid black",
                    overflow:'hidden',
                    ...style
                }}
                onClick={(event) => event.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
};
 
export default BigModal;