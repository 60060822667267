import { motion } from "framer-motion";
import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { updateCaller } from '../../services/api';
import ErrorBoundary from '../../error/ErrorBoundary';
import '../eventManage/leagueFromSport/leagueManage.css';
import { handleKeyDown } from '../../utilities/handleKeyDown';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import ToogleButton from '../../component/toggleButton/ToogleButton';
import { showToast, showDeleteMessage, showConfirmationDialog } from "../../utilities/toastHelper";

const HorseRacingVanueLeagueSettings = ({ leagueData, setLeagueData }) => {
  const [open, setOpen] = useState([]);

  const toggleAccordian = (index, setOpenState) => {
    setOpenState((prevState) =>
      prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
    );
  };

  const handleLeagueAccordian = (index) => {
    toggleAccordian(index, setOpen);
  };

  const handleDeleteLeague = async (id, i) => {
    const result = await showConfirmationDialog("You want to delete this league?");
    if (result.isConfirmed) {
      const response = await updateCaller(`api/v1/racing/country/${id}`, { "is_deleted": 1 });
      if (response.success === true) {
        showDeleteMessage("Your League is deleted Successfully!");
        const newList = [...leagueData];
        newList.splice(i, 1);
        setLeagueData(newList);
      } else {
        showToast(response.message, true);
      }
    }
  };

  const handleChangeFancy = (fieldName, index, value, k, j, val) => {    
    if (value < 0) value = 0;
    let newFormValues = [...leagueData]
    newFormValues[index].fancylimits[k].marketlimits['*'][fieldName] = value;
    setLeagueData(newFormValues)
    // updateVenueSetting(val, k, 'venue', false, fieldName);
  };

  const handleBlur = (fieldName, rowIndex, k, j) => {
    const jsonObject = leagueData[rowIndex]?.fancylimits[k];
    if (jsonObject) {
      updateVenueSetting(jsonObject, rowIndex, 'venue', false, fieldName);
    }
  };

  const updateVenueSetting = async (value, i, buttonNumber, isToggle = false, fieldName = "") => {
    const jsonObject = (value);
    let formattedFieldName = fieldName.replace(/_/g, ' ').replace(/\b\w/g, (letter) => letter.toUpperCase());

    const response = await updateCaller(`api/v1/racing/chanegemarketlimits`, { tableName: buttonNumber, jsonObject });

    if (response?.success === true) {
      const title = jsonObject.marketlimits['*'].is_active ? "Active" : "Inactive";
      showToast(isToggle === false ? `${formattedFieldName} updated successfully!` : title, false);
    } else if (response?.success === false) {
      const regexPattern = /jsonObject\.marketlimits\.\*\.\s*/g;
      let displayMessage = response.message.replace(regexPattern, "").trim();
      showToast(displayMessage, true);
    }
  };
  
  const toggleActiveFancy = (key, rowIndex, index, j,val) => {
    let newFormValues = [...leagueData];
    newFormValues[rowIndex].fancylimits[index].marketlimits[key].is_active = newFormValues[rowIndex].fancylimits[index].marketlimits[key].is_active === 0 ? 1 : 0;
    setLeagueData(newFormValues);
    updateVenueSetting(val, index, 'venue', true);
  };

  const handleLeagueStatuses = async (fieldName, value, index, leagueID) => {
    let newFormValues = [...leagueData]
    newFormValues[index][fieldName] = value ? 1 : 0;
    setLeagueData(newFormValues);
    
    let title = newFormValues[index][fieldName] === 1 ? "All matches are active." : "All matches are inactive.";
    const res = await updateCaller(`api/v1/racing/country/${leagueID}`, { [fieldName]: value });
    if (res) {
      showToast(title, false);
    }
  };
  
  return (
    <div className="">
      {
        leagueData?.length > 0 ? leagueData?.map((el, i) => (
          <motion.div className="league-setting-container" key={i} initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
            <div className="league-setting-body">
              <button className={`accordion ${open.includes(i) ? 'active' : 'inactive'}`} onClick={() => handleLeagueAccordian(i)}>
                {!open.includes(i) ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>} {el.code}
              </button>

              <div className={`league-setting-content `}>
                <div className='league-setting-btn'>  <p className='league-para'> Match Active </p> <ToogleButton defaultChecked={parseInt(el.is_active) === 0 ? false : true} onChange={(event) => handleLeagueStatuses("is_active", event, i, el.id)} /></div>
                <div className="delete-icon league_icon">
                  <MdDelete style={{ fontSize: "1rem", color: "white" }} onClick={() => handleDeleteLeague(el.id, i)} tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                        e.preventDefault(); handleDeleteLeague(el.id, i)
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={`market-accordian-body`} style={{ display: open ? 'block' : 'none' }}>
              <table width="100%">
                <tbody>
                  {open.includes(i) && (     
                    el.fancylimits.map((val, k) => (
                      Object.entries(val.marketlimits).map(([fieldName, keyIndex], j) => (
                        <tr className='league-setting-row' key={j}>
                          <td>{val.name}</td>
                          
                          <td><ToogleButton defaultChecked={parseInt(keyIndex.is_active) === 1} onChange={(event) => toggleActiveFancy(fieldName, i, k, j, val)}/></td>

                          <td>
                            <label htmlFor="stake_limit">Stake Limit</label> <br />
                              <input type='number' autoComplete="off" placeholder='Stake Limit' className='league-detail-input' disabled={parseInt(keyIndex.is_active) === 0} onKeyDown={handleKeyDown} value={keyIndex.stake_limit} onChange={(event) => handleChangeFancy('stake_limit', i, event.target.value, k, j, val)} onBlur={() => handleBlur('stake_limit', i, k, j)} />
                          </td>

                          <td>
                            <label htmlFor="odd_limit">Odd Limit</label> <br />
                            <input type='number' autoComplete="off" placeholder='Odd Limit' className='league-detail-input' disabled={parseInt(keyIndex.is_active) === 0} onKeyDown={handleKeyDown} value={keyIndex.odd_limit} onChange={(event) => handleChangeFancy('odd_limit', i, event.target.value, k, j, val)} onBlur={() => handleBlur('odd_limit', i, k, j)} />
                          </td>

                          <td>
                            <label htmlFor="inplay_stake_limit">Inplay Stake Limit</label> <br />
                            <input type='number' autoComplete="off" placeholder='Inplay Stake Limit' className='league-detail-input' disabled={parseInt(keyIndex.is_active) === 0} onKeyDown={handleKeyDown} value={keyIndex.inplay_stake_limit} onChange={(event) => handleChangeFancy('inplay_stake_limit', i, event.target.value, k, j, val)} onBlur={() => handleBlur('inplay_stake_limit', i, k, j)} />
                          </td>

                          <td>
                            <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                            <input type='number' autoComplete="off" placeholder='Max Market Limit' className='league-detail-input' disabled={parseInt(keyIndex.is_active) === 0} onKeyDown={handleKeyDown} value={keyIndex.max_market_limit} onChange={(event) => handleChangeFancy('max_market_limit', i, event.target.value, k, j, val)} onBlur={() => handleBlur('max_market_limit', i, k, j)} />
                          </td>

                          <td>
                            <label htmlFor="delay">Delay</label> <br />
                            <input type='number' autoComplete="off" placeholder='Delay' className='league-detail-input' disabled={parseInt(keyIndex.is_active) === 0} onKeyDown={handleKeyDown} value={keyIndex.delay} onChange={(event) => handleChangeFancy('delay', i, event.target.value, k, j, val)} onBlur={() => handleBlur('delay', i, k, j)} />
                          </td>
                        </tr>
                      ))
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </motion.div>
        )) : <ErrorBoundary />
      }
    </div>
  )
};

export default HorseRacingVanueLeagueSettings;