import '../fancyMarket/fancyTab.css';
import React, { useState } from 'react';
import ErrorBoundary from '../../../error/ErrorBoundary';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

const FancyMarketOdds = ({ fancy }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className="fancy-market-accordian">
      <div className="fancy-market-content" onClick={() => setIsActive(!isActive)}>
        <div className="icon-fancy">
          <p>Fancy Market</p>
          {isActive ? <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronUp style={{ fontSize: "1rem", fontWeight: "bold" }} /></>}
        </div>
      </div>
      
      {isActive &&
        <div className="fancy-accordian-content">
          {
            fancy?.ml?.length > 0 ? fancy?.ml?.map((item, index) => (
              <div className='content-fancy-odds' key={index}>
                <div className="">
                  <p> {item.mn} </p>
                </div>
                <div className="fancy-back-lay-container">
                  <div className="">
                    <div className='fancy-suspended'>
                      {item?.sn === "ONLINE" ? null : <h2 className='suspend-fancy'> {item.sn} </h2>}
                      <div className="all-box-bookmaker" style={{ background: "#8dd2f0" }}>
                        <div className="">
                          <p className='odd-price'>{item?.sn === "ONLINE" ? item.rn : null} </p>
                          <p className='odd-price-1'> {item?.sn === "ONLINE" ? item.on + 'k' : null}</p>
                        </div>
                      </div>
                      <div className="all-box-bookmaker" style={{ background: "#feafba" }}>
                        <div className="">
                          <p className='odd-price'> {item?.sn === "ONLINE" ? item.ry : null} </p>
                          <p className='odd-price-1'>{item?.sn === "ONLINE" ? item.oy + 'k' : null}</p>
                        </div>
                      </div>
                    </div>
                    <div className="min-max-fancy">
                      <p className='min-max'>Min: {item?.mms?.split('-')?.[0]} | Max: {item?.mms?.split('-')?.[1]}</p>
                    </div>
                  </div>
                </div>
              </div>
            )) : <ErrorBoundary />
          }
        </div>
      }
    </div>
  )
}

export default FancyMarketOdds