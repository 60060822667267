import '../eventManage/eventmanage.css';
import { motion } from "framer-motion";
import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../error/ErrorBoundary';
import { showToast } from '../../utilities/toastHelper';
import { getCaller, updateCaller } from '../../services/api';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import Pagination from '../../component/pagination/Pagination';
import ToogleButton from '../../component/toggleButton/ToogleButton';

const ManageTossBookmaker = () => {
    const [marketData, setMarketData] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const itemsPerPage = 15;

    const fetchMarketData = async (page = 1) => {
        try {
            if (fetchedPages[page]) {
                setMarketData(fetchedPages[page]);
                return;
            }
            const response = await getCaller(`api/v1/bookmakers/getData?page=${page}&limit=${itemsPerPage}`);
            const fetchedData = response.data;
            setMarketData(fetchedData);
            setTotalPage(response?.pagination?.totalPages);
            setFetchedPages((prev) => ({ ...prev, [page]: fetchedData }));
        } catch (error) {
            console.error('Error fetching market data:', error);
        }
    };

    useEffect(() => {
        fetchMarketData();
    }, []);

    const toggleEventDetails = (eventId) => {
        setSelectedEvent(selectedEvent === eventId ? null : eventId);
    };

    const handleInputChange = (event, marketId, field) => {
        const updatedData = marketData.map(market => {
            if (market.id === marketId) {
                return {
                    ...market,
                    [field]: (field === 'time_range') ? event.target.value : event.target.value.replace(/[^0-9]/g, '')
                };
            }
            return market;
        });
        setMarketData(updatedData);
    };

    const handleToggleChange = (marketId, field, value) => {
        const updatedData = marketData.map(market => {
            if (market.id === marketId) {
                return {
                    ...market,
                    [field]: value === true ? 1 : 0
                };
            }
            return market;
        });
        setMarketData(updatedData);
    };

    const handleUpdate = async (market) => {
        const updatedBookmakerData = {
            stake_limit: market.stake_limit || 0,
            max_market_limit: market.max_market_limit || 0,
            bet_delay: market.bet_delay || 0,
            time_range: market.time_range,
            is_sus: market.is_sus ? 1 : 0,
            is_active: market.is_active,
            is_com: market.is_com,
        };
        
        try {
            const response = await updateCaller(`api/v1/bookmakers/updateToss/${market.id}`, updatedBookmakerData);
            if (response.message === 'SUCCESS') {
                showToast('Toss bookmaker has been updated.');
            } else {
                showToast(response.message, true);
            }
        } catch (error) {
            showToast('Failed to update toss bookmaker.', true);
        }
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' , hour: '2-digit',  minute: '2-digit',  second: '2-digit'};
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate.replace(',','');
    };
    const handlePageChange = async(page) => {
        setCurrentPage(page);
        await fetchMarketData(page);
    };

    const filteredData = marketData?.filter(market => 
        market?.event_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    return (
        <div className="wrapper">
            <div className="wrapper-head">
                <div className="wrapper-content" style={{ width: "100%" }}>
                    <h3> Market Toss Bookmaker </h3>
                    <input 
                        type="search" 
                        autoComplete="off" 
                        placeholder='Search' 
                        className='result-input' 
                        style={{ width: '20%' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <motion.div className="league-setting-container"  initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                <div className="market-section">
                    {filteredData.length > 0 ? (
                        <>
                            {filteredData.map((market, index) => (
                                <div key={index} className="market-section" style={{ borderBottom: "groove", padding: "8px" }}>
                                    <button className={`accordion ${selectedEvent === market.event_id ? 'active' : 'inactive'}`} onClick={() => toggleEventDetails(market.event_id)} style={{ width: '100%', whiteSpace: 'nowrap' }}>
                                        {
                                            selectedEvent === market.event_id ? (
                                                <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                            ) : (
                                                <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                            )
                                        }&nbsp;
                                        <p>{market.event_name} - {formatDate(market.event_date)}</p>
                                    </button>

                                    {selectedEvent === market.event_id && (
                                        <div className="table-border tableRes">
                                            <table width="100%">
                                                <thead>
                                                    <tr className='headingRes'>
                                                        <th>Stake Limit</th>
                                                        <th>Max Market Limit</th>
                                                        <th>Bet Delay</th>
                                                        <th>Expire Time</th>
                                                        <th>Suspend</th>
                                                        <th>Active</th>
                                                        <th>Commission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='custom-row'>
                                                        <td>
                                                            <input
                                                                autoComplete="off"
                                                                type="text"
                                                                inputMode="numeric"
                                                                placeholder="Stake Limit"
                                                                value={market.stake_limit}
                                                                onChange={(e) => handleInputChange(e, market.id, 'stake_limit')}
                                                                className="league-detail-input"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                autoComplete="off"
                                                                type="text"
                                                                inputMode="numeric"
                                                                placeholder="Max Market Limit"
                                                                value={market.max_market_limit}
                                                                onChange={(e) => handleInputChange(e, market.id, 'max_market_limit')}
                                                                className="league-detail-input"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                autoComplete="off"
                                                                type="text"
                                                                inputMode="numeric"
                                                                placeholder="Bet Delay"
                                                                value={market.bet_delay || ''}
                                                                onChange={(e) => handleInputChange(e, market.id, 'bet_delay')}
                                                                className="league-detail-input"
                                                            />
                                                        </td>
                                                        <td>
                                                            <select value={market.time_range || '1'} onChange={(e) => handleInputChange(e, market.id, 'time_range')} className="league-detail-input">
                                                                <option value="1">1 Hour</option>
                                                                <option value="1.5">1.30 Hour</option>
                                                                <option value="2">2 Hour</option>
                                                                <option value="2.5">2.30 Hour</option>
                                                                <option value="3">3 Hour</option>
                                                                <option value="3.5">3.30 Hour</option>
                                                                <option value="4">4 Hour</option>
                                                            </select>
                                                        </td>
                                                        <td align="left">
                                                            <div className="table-flex">
                                                                <ToogleButton onChange={(value) => handleToggleChange(market.id, 'is_sus', value)} defaultChecked={market.is_sus === 1} />
                                                            </div>
                                                        </td>
                                                        <td align="left">
                                                            <div className="table-flex">
                                                                <ToogleButton onChange={(value) => handleToggleChange(market.id, 'is_active', value)} defaultChecked={market.is_active === 1} />
                                                            </div>
                                                        </td>
                                                        <td align="left">
                                                            <div className="table-flex">
                                                                <ToogleButton onChange={(value) => handleToggleChange(market.id, 'is_com', value)} defaultChecked={market.is_com === 1} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="custom-row">
                                                        <td colSpan={7}>
                                                            <button className="update-league-btn" type="button" onClick={() => handleUpdate(market)}> Update </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            ))
                            }
                            <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
                        </>
                    ) : <ErrorBoundary />
                    }
                </div>
            </motion.div>
        </div>
    );
};

export default ManageTossBookmaker;
