import moment from 'moment';
import 'react-dates/initialize';
import '../addEvent/addevent.css';
import Select from 'react-select';
import { motion } from "framer-motion";
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { postCaller } from '../../services/api';
import Button from '../../component/button/Button';
import React, { useState, useEffect } from 'react';
import { selectStyles } from '../../services/utils';
import { showToast } from '../../utilities/toastHelper';
import ErrorBoundary from '../../error/ErrorBoundary';
import Pagination from '../../component/pagination/Pagination';

const UserLogs = () => {
    const [startDate, setStartDate] = useState(moment().subtract(6, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 100;

    let minDate = moment().subtract(7, 'days').toDate();

    const handleDate = (date) => {
        let day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
        let month = date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
        return `${month}/${day}/${date.getFullYear()}`;
    };

    const typeOptions = [
        { value: 'admin_password', label: 'Admin Password' },
        { value: 'manager_password', label: 'Manager Password' },
        { value: 'agent_password', label: 'Agent Password' },
        { value: 'bookmaker_add', label: 'Bookmaker Add' },
        { value: 'bookmaker_remove', label: 'Bookmaker Remove' },
        { value: 'fancy_add', label: 'Fancy Add' },
        { value: 'fancy_remove', label: 'Fancy Remove' },
    ];

    useEffect(() => {
        getUserLogs();
    }, []);

    const getUserLogs = async (page = 1) => {
        if (page > 1&&fetchedPages[page]) {
            setLogs(fetchedPages[page]);
            return;
        }
        const data = {
            from: handleDate(startDate._d),
            to: handleDate(endDate._d),
            ...(selectedType && { type: selectedType.value }),
            ...(searchText && { manager_id:searchText, agent_id:searchText, market_id:searchText }), 
        };

        const response = await postCaller(`api/v1/user-logs?page=${page}&limit=${limit}`, data);
        if (response.success) {
            setLogs(response.data);
            setTotalPage(response?.pagination?.totalPages);
            setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
        } else {
            showToast(response.message, true);
        }
    };
    
    useEffect(() => {
        setFetchedPages({});
    }, [startDate, endDate, selectedType, searchText]);

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const getLogTypeLabel = (logType) => {
        const option = typeOptions.find((type) => type.value === logType);
        return option ? option.label : logType;
    };

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await getUserLogs(page);
        }
    };

    return (
        <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
            <div className="wrapper-head">
                <h2>User Logs</h2>
            </div>
            <div className="result-sport-container">
                <div className="result-sport-content" style={{ justifyContent: 'flex-start', gap: '15px' }}>
                    <div className="select-container containerStyle" style={{ marginLeft: '8px', maxWidth: '280px', marginTop: '-3px' }}>
                        <label htmlFor="date" id="date-label">Select Date</label> <br />
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="s_id"
                            endDate={endDate}
                            endDateId="e_id"
                            onDatesChange={handleDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={setFocusedInput}
                            displayFormat="DD/MM/YYYY"
                            isOutsideRange={() => false}
                            // isDayBlocked={(d) => d.toDate() < minDate}
                            minimumNights={0}
                            readOnly
                        />
                    </div>

                    <div className="select-container" style={{ maxWidth: '280px' }}>
                        <label htmlFor="sport">Select Type</label> <br />
                        <Select
                            styles={selectStyles}
                            placeholder="Select Type"
                            options={typeOptions}
                            value={selectedType}
                            onChange={handleTypeChange}
                            isClearable={true}
                        />
                    </div>

                    <div className="select-container" style={{ width: '200px' }}>
                        <label htmlFor="search">Search by ID</label> <br />
                        <input
                            type="search"
                            autoComplete="off"
                            placeholder="Enter ID"
                            className="result-input"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </div>

                    <div className="wrapper-content">
                        <div className="custom-btn" style={{ marginTop: '20px' }}>
                            <Button className='submit' type='button' name='Search' onClick={async() => await getUserLogs(1)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-border">
                <div className="wrapper-body" style={{ margin: '0 -8px 0 -8px' }}>
                    <div className="wrapper-content" style={{ width: "100%" }}>
                        <h2>List of User Logs</h2>
                    </div>
                </div>

                {logs.length === 0 ? (
                    <ErrorBoundary />
                ) : (
                    <table className="odds-gap-table">
                        <thead>
                            <tr style={{ whiteSpace: 'nowrap', color: '#003366', padding: '20px', backgroundColor: '#e0ebeb' }}>
                                <th>Manager ID</th>
                                <th>Agent ID</th>
                                <th>Log Type</th>
                                <th>Description</th>
                                <th>IP Address</th>
                                <th>Log Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map((log, index) => (
                                <tr key={log.id}>
                                    <td>{log.manager_id}</td>
                                    <td>{log.agent_id}</td>
                                    <td>{getLogTypeLabel(log.log_type)}</td>
                                    <td>{log.log_data}</td>
                                    <td>{log.ip_address}</td>
                                    <td>{moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
             
        </motion.div>
    );
};

export default UserLogs;