import React from 'react';
import '../user/user.css';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from 'react-icons/ai';
import { postCaller } from '../../services/api';
import Button from '../../component/button/Button';
import { showToast, showConfirmationDialog, showSuccessMessage } from '../../utilities/toastHelper';

const AdminChangePassword = ({ open, setOpen }) => {
    const Schema = Yup.object().shape({
        password: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required").when("password", {
          is: val => (val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          )
        }),
        managerPassword: Yup.string().required("Old Password is required"),
    });
    
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            managerPassword: ''
        },
        validationSchema: Schema,
        onSubmit: async (values) => {
            delete values.confirmPassword;

            const userConfirmed = await showConfirmationDialog("You want to change the Admin Password?");
            if (userConfirmed.isConfirmed === false) {
                formik.resetForm();
                return;
            }

            const ChangePasswordResponse = await postCaller(`api/v1/users/changePasswordAgent?id=${'admin'}`, values);

            if (ChangePasswordResponse.success === true) {
                showToast("Admin Password has been changed.")
                navigate('/user');
            } else {
                showToast(ChangePasswordResponse.message, true);
            }
        },
    });

    return (
        <div className="sport-container">
            <div className="modal-container-one">
                <div className='add-agent-head'>
                    <div>
                        Admin Password Change 
                    </div>
                    <div className="ai-close">
                        <AiOutlineClose onClick={() => { navigate('/user') } }/>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='agent-add-form'>
                        <div className="agent-input-body">
                            <label> NEW PASSWORD <span style={{ color: 'red' }}>*</span> </label> <br />
                            <input 
                                type="password" 
                                autoComplete="off" 
                                name='password' 
                                onBlur={formik.handleBlur} 
                                value={formik.values.password} 
                                onChange={formik.handleChange} 
                                className='agent_input'
                                placeholder='New Password' 
                            />
                            {formik.errors.password && formik.touched.password && 
                                <span className="error" style={{ color: "red" }}>
                                    {formik.errors.password}
                                </span>
                            }
                        </div>
                        <div className="agent-input-body">
                            <label> CONFIRM PASSWORD <span style={{ color: 'red' }}>*</span> </label> <br />
                            <input 
                                type="password" 
                                autoComplete="off" 
                                name='confirmPassword' 
                                value={formik.values.confirmPassword} 
                                onBlur={formik.handleBlur} 
                                onChange={formik.handleChange} 
                                className='agent_input' 
                                placeholder='Confirm Password'  
                            />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword && 
                                <span className="error" style={{ color: "red" }}>
                                    {formik.errors.confirmPassword}
                                </span>
                            }
                        </div>
                        <div className="agent-input-body">
                            <label> OLD PASSWORD <span style={{ color: 'red' }}>*</span> </label> <br />
                            <input 
                                type="password" 
                                autoComplete="off" 
                                name='managerPassword' 
                                onBlur={formik.handleBlur} 
                                value={formik.values.managerPassword} 
                                onChange={formik.handleChange} 
                                className="agent_input" 
                                placeholder='Old Password'  
                            />
                            {formik.errors.managerPassword && formik.touched.managerPassword && 
                                <span className="error" style={{ color: "red" }}>
                                    {formik.errors.managerPassword}
                                </span>
                            }
                        </div>
                        <div className="change-password-container">
                            <Button type="submit" name="Submit" className="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminChangePassword;
