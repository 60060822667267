import moment from 'moment';
import { AiOutlineClose } from "react-icons/ai";
import { postCaller } from '../../services/api';
import React, { useState, useEffect } from 'react';
import BigModal from '../../component/modal/BigModal';
import Paginations from '../../component/pagination/Paginations';

const SurveillanceBets = ({ isModalOpen, setModalOpen, sportId, userId, marketId, marketType, matchId, operator, isChanged, setIsChanged, userBetList }) => {
    const [betList, setBetList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(50);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        (async () => {
            if (userId && marketId && marketType && matchId && sportId && isChanged) {
                setIsChanged(false);
                await getEvents();
            }
        })();
    }, [isChanged]);

    async function getEvents(page = currentPage) {
        try {
            const data = {
                sportId,
                user: userId,
                match_id: matchId,
                market_id: marketId,
                market_type: marketType,
                page: page,
                limit: perPage,
                bet_type: "sports",
                bet_status: "1,2,3",
            }

            setLoading(true);
            const betResponse = await postCaller(`api/v1/surveillance/fetch-bets/${operator}`, data);
            setBetList(betResponse?.data?.data?.data || []);

            const { per_page, current_page, last_page, total: totalData } = betResponse?.data?.data;
            setCurrentPage(current_page);
            setPerPage(per_page);
            setTotalPage(last_page);
            setTotal(totalData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const handlePageChange = async (page) => {
        try {
            setCurrentPage(page);
            await getEvents(page);
        } catch (error) {
            console.error(error);
        }
    }

    const handleOnClose = async () => {
        setBetList([]);
        setCurrentPage(1);
        setPerPage(50);
        setModalOpen(false);
    }

    return (
        <div>
            <BigModal isOpen={isModalOpen} onClose={handleOnClose} style={{ width: "87rem" }}>
                <div className="add-agent-head">
                    <h3> Total Bets of {userId} : {total}</h3> <br />
                    <div className="ai-close">
                        <AiOutlineClose onClick={handleOnClose} />
                    </div>
                </div>
                {loading ? <div className="loader-wrapper">
                    <div className='loader'>
                    </div>
                </div> :
                    <div className="wrapper">
                        <div className="table-border">
                            <div className="wrapper-body" style={{ overflowX: "auto", width: "81rem" }}>
                                <div style={{ maxHeight: '450px', width: '100%' }}>
                                    <table style={{ width: '80rem' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Selection</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}></th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Odds</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Requested Odds</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Stake</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Placed At</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Status</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Profit/Loss</th>
                                                <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>IP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {betList.map((row) => (
                                                <tr key={row.id} className={`${row.selection_type} custom-row`}>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.selection}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{userBetList.includes(row.id) && <span className="surveillance-label">Surveillance Bet</span>}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.requested_odds || row.odds}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.odds}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.stake}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{ moment.utc(row.created_at).local().format('DD-MM-YYYY HH:mm:ss') }</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.status}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.profit + "/" + row.loss}</td>
                                                    <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.ip}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Paginations page={currentPage} perPage={perPage} setPage={handlePageChange} setPerPage={setPerPage} maximo={totalPage} />
                    </div>
                }
            </BigModal>
        </div>
    );
};

export default SurveillanceBets;