import React, { useState } from "react";
import { motion } from "framer-motion";
import '../leagueFromSport/leagueManage.css';
import MatchAccordian from "./MatchAccordian";
import { getCaller } from "../../../services/api";
import ErrorBoundary from '../../../error/ErrorBoundary';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";

const LeagueAccordian = ({ league, setLeagueData, leagueData, index }) => {
  const [open, setOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [sportId, setSportId] = useState('')

  const getMatchFromLeague = async (status, sportId) => {
    setSportId(sportId)
    if (!matches || matches.length === 0) {
      const leagueResponse = await getCaller(`api/v1/racing/events/venue/${league.id}`)
      setMatches(leagueResponse.data);
      setOpen(true)
    }
    setOpen(!open);
  }


  return (
    <>
      <motion.div className="league-setting-container" initial={{ opacity: 0, x: '-100vh' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}>
        <div className="league-setting-body">
          <button className={`accordion ${open ? 'active' : 'inactive'}`} disabled={parseInt(league.is_active) === 0} onClick={() => getMatchFromLeague(league.is_active, league.sport_id)} >
            {!open ? <><BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold" }} /></> : <><BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold" }} /></>} {`${league.code}-${league.name} `}
          </button>

        </div>
      </motion.div>
      <div className="league-accordian-container" style={{ display: open ? 'block' : 'none' }}>
        {
          matches?.length > 0
            ? matches?.map((match, i) => <MatchAccordian match={match} key={i} index={i} setMatches={setMatches} matches={matches} sportId={sportId} leagueData={leagueData} setLeagueData={setLeagueData} />)
            : <ErrorBoundary />}
      </div>

    </>
  )
}
export default LeagueAccordian