import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import BookmakerResult from '../ManagerManageMarket/BookmakerResult'
import { motion } from "framer-motion";
import FancyResultMaker from '../ManagerManageMarket/FancyResultMaker'
import MainMakerResult from './MainMarketResultMaker'
import { getCaller } from '../../../services/api';
import Button from '../../../component/button/Button';
import '../downline.css'
import { disablePastDate } from '../../../services/utils';

const AddDownlineMatch = () => {
    const [marketOpen, setMarketOpen] = useState(false);
    const [marketType, setMarketType] = useState("")
    const [sportList, setSportList] = useState([])
    const [sport, setSport] = useState("")
    const [match, setMatch] = useState("")
    const [eventlist, setEventList] = useState([]);
    const [marketList, setMarketList] = useState([]);
    const [fancyMarketType, setFancyMarketType] = useState('')
    const [marketData, setMarketData] = useState([])
    const { state } = useLocation()
    const userName = state?.name;

    const today = new Date();

    // Calculate the date 7 days from today
    const dateAfter7Days = new Date();
    dateAfter7Days.setDate(today.getDate());
    const [createMarket, setCreateMarket] = useState({
        event_date: dateAfter7Days.toISOString().split('T')[0],
        sus_time: userName?.sus_time
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name === 'event_date'){
            setSport("");
            setEventList([]);
            setMatch("");
            setMarketType("");
            setFancyMarketType("");
            setMarketList([]);
            setMarketData([]);
        }
        
        if(name === 'sus_time'){
            const filteredValue = value.replace(/[^0-9]/g, '')
            setCreateMarket({ ...createMarket, [name]: filteredValue})
        } else{
            setCreateMarket({ ...createMarket, [name]: value })
        }
        
    }
    useEffect(() => {

        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse.data)
        }
        getSport()
    }, [])
   
    const handleSport = async (event) => {
        const sport = event.target.value
        setSport(sport)
        
        if (sport === "") return
        
        const response = await getCaller(`api/v1/events/findEvents?id=${sport}&event_date=${createMarket.event_date}`)
        
        
        setEventList(response.data);
        setMatch("");
        setMarketType("");
        setFancyMarketType("");
        setMarketList([]);
        setMarketData([]);
    }

    const handleEventList = async (event) => {
        const eventList = event.target.value
        setMatch(eventList);
        
        if (eventList === "") return setMarketList([])

        const response = [];
        if(JSON.parse(eventList)?.is_custom === 1){
            response.push({ id: 'main_market', name: 'Main Market' })
        }

        if ( parseInt(sport) !== 1 || parseInt(sport) !== 2) {
            response.push({ id: 'bookmaker', name: 'Bookmaker' });
            response.push({ id: 'fancy', name: 'Fancy' })
        }

        setMarketList(response);
        setMarketType("");
        setFancyMarketType("");
    }

    const handleMarketType = async (event) => {
        const market = event.target.value
        setMarketType(market);
        setFancyMarketType("");
        if (market === 'main_market') {
            setMarketData([{ value: "match odds", name: "Match odds" }]);
        } else if (market === 'bookmaker') {
            setMarketData([]);
        } else if (market === 'fancy') {
            if (![1,3].includes( parseInt(sport))) {

                setMarketData([
                    { value: "line_markets", name: "Line Markets" },
                    { value: "session_markets", name: "Session Markets" },
                    { value: "over_by_over_session_markets", name: "Over by Over Session Markets" },
                    { value: "ball_by_ball", name: "Ball By Ball" },
                    { value: "total_advance", name: "Total Advance" },
                    { value: "fall_of_wicket", name: "Fall of Wicket" },
                    { value: "meter_markets", name: "Meter Markets" },
                    { value: "khado_markets", name: "Khado Markets" },
                    { value: "odd_even_markets", name: "Odd Even Markets" },
                    { value: "other_markets", name: "Other Markets" },
                    { value: "exchange_multi_runner_markets", name: "Exchange Multi Runner Markets" },
                    { value: "sportsbook_multi_runner_markets", name: "Sportsbook Multi Runner Markets" },
                    { value: "under_over_multi_runner_markets", name: "Under Over Multi Runner Markets" }
                ]);
            } else {
                setMarketData([]);
            }
        }else{
            setMarketData([]);
        }
    }

    const handleFancyType = (event) => {
        setFancyMarketType(event.target.value)
        if (event.target.value === "") return
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setMarketOpen(true);
        
    }
    return (
            <motion.div className="wrapper" initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                <div className="wrapper-head">
                    <h3>MANAGE MARKET OF <span className='userName'>{userName.user_id}</span>  </h3>
                    <div className='offline' > <Button className={'submit'} name={'Offline'} type={'button'} /> </div>
                </div>
                <div className="result-sport-container">
                    <div className="result-sport-content">
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Date </label> <br />
                            <input type='date' autoComplete="off"
                                min={disablePastDate()}
                                name='event_date' value={createMarket.event_date} onChange={(e) => handleChange(e)} className='select-field' placeholder='DD/MM/YYYY' />
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Sport </label> <br />
                            <select className='select-field manager_downline'
                                value={sport} onChange={async (event) => {handleSport(event, sport) }}>
                                <option value="" disabled> Select Sport</option>
                                {sportList?.length > 0 ? sportList?.map((el) => <option
                                    key={el.id} value={el.id}>{el.name}</option>) : null}
                            </select>
                        </div>
                        <div className="result-event-select"> 
                            <label htmlFor="date">Select Event</label>
                            <br />
                            <select className='select-field manager_downline' name='event_name' value={match} onChange={async (event) => { handleEventList(event) }} >
                                <option value="" disabled> Select Event</option>
                                {eventlist?.length > 0 ? eventlist?.map((el) => <option key={el.event_id} value={JSON.stringify(el)}>{el.event_name}</option>) : null}
                            </select>
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Market Type </label> <br />
                            <select className='select-field manager_downline' value={marketType} onChange={event => { handleMarketType(event, marketType); setMarketOpen(false) }}>
                                <option value="" disabled> Select Market Type</option>
                                {
                                    marketList?.length > 0 ? marketList.map((e, i) => (
                                        <option key={i} value={e.id}> {e?.name} </option>
                                    )) : null
                                }
                                 
                            </select>
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <select className='select-field manager_downline' value={fancyMarketType} disabled={marketType === 'bookmaker' } onChange={event => { handleFancyType(event, fancyMarketType) }} >
                                <option value="" disabled > Select Market</option>
                                {
                                (marketData || []).map((e, i) => (
                                    <option value={e.value} key={i}> {e.name} </option>
                                ))
                                }
                            </select>
                        </div>

                        <div className="result-fancy-select">
                            <label htmlFor="date"> Suspensions </label> <br />
                            <input  type="number" inputMode="numeric" autoComplete="off" name="sus_time" className='select-field' value={createMarket?.sus_time || ""} onChange={handleChange} />
                        </div>
                    </div>
                    <div className='market-assign-btn'>

                        <Button type='submit' onClick={(e) => handleSubmit(e)} className='submit' name="Get Market" disabled={!createMarket?.sus_time || !marketType || !match || !sport || !createMarket?.event_date || marketType === 'fancy'? !fancyMarketType:null} />

                    </div>
                </div>

                {
                    marketType === 'main_market' && marketOpen?
                        <MainMakerResult event={ JSON.parse(match || {}) } susTime={createMarket?.sus_time} sport={sport} setMarketOpen={setMarketOpen} uid={userName.id} userName={userName} />
                    :null
                }
                
                {
                    marketType === 'bookmaker' && marketOpen?
                        <BookmakerResult event={ JSON.parse(match || {}) } sport={sport} setMarketOpen={setMarketOpen} uid={userName.id} susTime={createMarket?.sus_time} />
                    :null
                }

                {
                    marketType === 'fancy' && marketOpen?
                        <FancyResultMaker event={ JSON.parse(match || {}) } sport={sport} setMarketOpen={setMarketOpen} marketType={marketType} uid={userName.id} userName={userName} fancyMarketType={fancyMarketType} susTime={createMarket?.sus_time} />
                    :null
                }

            </motion.div>
    )
}

export default AddDownlineMatch