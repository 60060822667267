import React, { useState, useEffect } from 'react';
import { getCaller, updateCaller } from "../../../services/api";
import { showSuccessMessage, showToast } from "../../../utilities/toastHelper";
import Pagination from '../../../component/pagination/Pagination';

const AgentOddSettings = ({ onSaveClose }) => {
    const [oddsGapList, setOddsGapList] = useState([]);
    const [selectedGaps, setSelectedGaps] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [userId, setUserId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 20;

    const fetchOddsGaps = async (page = 1) => {
        if (page > 1&&fetchedPages[page]) {
            setOddsGapList(fetchedPages[page]);
            return;
        }
        try {
            const response = await getCaller(`api/v1/settings/alloddGapSettings?page=${page}&limit=${limit}`);
            setOddsGapList(response.data);
            setTotalPage(response?.pagination?.totalPages);
            setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
        } catch (error) {
            console.error("Failed to fetch odds gap settings:", error);
        }
    };

    useEffect(() => {
        const fetchUserIdAndGaps = async () => {
            try {
                const response = await getCaller(`api/v1/users/showAssignMarketAgent`);
                if (response.success) {
                    setUserId(response.data.id);
                    const userResponse = await getCaller(`api/v1/users/${response.data.id}`);
                    if (userResponse.success) {
                        setSelectedGaps(JSON.parse(userResponse.data.odd_gap_ids) || []);
                    }
                }
            } catch (error) {
                console.error("Failed to fetch user ID or settings:", error);
            }
        };

        fetchOddsGaps();
        fetchUserIdAndGaps();
    }, []);

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await fetchOddsGaps(page);
        }
    };

    const handleCheckboxChange = (id) => {
        if (selectedGaps.includes(id)) {
            setSelectedGaps(selectedGaps.filter(gapId => gapId !== id));
        } else {
            setSelectedGaps([...selectedGaps, id]);
        }
    };

    const handleSave = async () => {
        try {
            const updateResponse = await updateCaller(`api/v1/users/updateUser/${userId}`, { odd_gap_ids: selectedGaps });
            if (updateResponse.success) {
                showSuccessMessage('Odds gap settings have been successfully updated.')
                onSaveClose();
            }
        } catch (error) {
            showToast('Failed to update odds gap settings',true)
        }
    };

    const filteredOddsGaps = oddsGapList.filter(gap => gap.odds_no?.toString()?.includes(searchTerm) || gap.odds_yes?.toString()?.includes(searchTerm));

    const oddsNoCount = oddsGapList.reduce((acc, gap) => {
        acc[gap.odds_no] = (acc[gap.odds_no] || 0) + 1;
        return acc;
    }, {});

    return (
        <div className="wrapper">
            <div className="wrapper-head" style={{ marginTop: '12px' }}>
                <h3>Odds Gap List</h3>
                <input 
                    type="search" 
                    autoComplete="off" 
                    placeholder='Search Odds' 
                    className='result-input' 
                    style={{ width: '30%' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="table-border">
                <div className="wrapper-body">
                    <div className="oddsTable-wrap" style={{ maxHeight:'400px', overflow:'auto', width:'100%'}}>
                        <table className="odds-gap-table" style={{ maxWidth: '650px' }}>
                            <thead>
                                <tr>
                                    <th>Odds No</th>
                                    <th>Odds Yes</th>
                                    <th>Select</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOddsGaps.map((gap) => {
                                    const isChecked = selectedGaps.includes(gap.id);
                                    const isDisabled = oddsNoCount[gap.odds_no] > 1 && !isChecked && selectedGaps.some(gapId => oddsGapList.find(g => g.id === gapId)?.odds_no === gap.odds_no);
                                    return (
                                        <tr key={gap.id}>
                                            <td>{gap.odds_no}</td>
                                            <td>{gap.odds_yes}</td>
                                            <td>
                                                <input 
                                                    type="checkbox" 
                                                    checked={isChecked} 
                                                    disabled={isDisabled} 
                                                    onChange={() => handleCheckboxChange(gap.id)} 
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="logPagination">
                        <div className="input-container market-count" style={{ display: 'flex', marginRight: 'auto', padding: '3px', height: '43px'}}>
                            <button className="basic-button submit" type="button" onClick={handleSave}
                                style={{ 
                                    marginLeft: '0px', 
                                    backgroundColor: selectedGaps.length > 0 ? '#018990' : '#ccc', 
                                    color: selectedGaps.length > 0 ? 'white' : '#666',
                                    cursor: selectedGaps.length > 0 ? 'pointer' : 'not-allowed'
                                }}
                                disabled={selectedGaps.length === 0}
                            >
                                Save
                            </button>
                        </div>
                        <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
                        </div>
                </div>
            </div>
        </div>
    );
};

export default AgentOddSettings;