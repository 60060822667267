import '../downline.css';
import React, { useState} from 'react';
import { MdDelete, MdPostAdd } from 'react-icons/md';
import { handleKeyDown } from '../../../utilities/handleKeyDown.js';
import ToogleButton from '../../../component/toggleButton/ToogleButton';

const FancyAssignAddOdds = ({ market_type, si, onAddOdds, onRemoveOdds }) => {

    const generateInitialOdds = (market_type) => {
        const marketTypeList = {
            "exchange_multi_runner_markets": {
                selection_name: '',
                runNo: '',
                runYes: '',
                oddsNo: '',
                oddsYes: '',
                suspend: 0,
                si: si
            },
            "sportsbook_multi_runner_markets": {
                selection_name: '',
                back: '',
                suspend: 0,
                si: si
            },
            "under_over_multi_runner_markets" : {
                selection_name: '',
                line: '',
                over: '',
                under: '',
                suspend: 0,
                si: si
            }
        };

        return marketTypeList[market_type];
    };

    const [newOdds, setNewOdds] = useState(generateInitialOdds(market_type));

    const handleInputChange = (field, value) => {
        if (value < 0) {
            return; 
        }
        setNewOdds(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSubmit = () => {
        onAddOdds({
            ...newOdds
        });

        setNewOdds(generateInitialOdds(market_type)[0]); 
        onRemoveOdds();
    };

    return (
        <>  
            <div style={{ overflowX: "auto" }}>
                <table width="100%">
                    <tbody>   
                        <tr>
                            <td>
                                <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={newOdds.selection_name} onChange={(e) => handleInputChange('selection_name', e.target.value)} placeholder='Selection Name'/> 
                            </td>

                            {market_type === "under_over_multi_runner_markets" ? (
                                <>
                                    <td><input type="number" autoComplete="off" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.line} onChange={(e) => handleInputChange('line', e.target.value)} placeholder="Line"/></td>
                                    <td><input type="number" autoComplete="off" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.under} onChange={(e) => handleInputChange('under', e.target.value)} placeholder="Under"/></td>
                                    <td><input type="number" autoComplete="off" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.over} onChange={(e) => handleInputChange('over', e.target.value)} placeholder="Over"/></td>
                                </>
                            ) : market_type === "sportsbook_multi_runner_markets" ? (
                                <>
                                    <td><input type="number" autoComplete="off" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.back} onChange={(e) => handleInputChange('back', e.target.value)} placeholder="Back"/></td>
                                </>
                            ) : (
                                market_type !== "khado_markets" && market_type !== "odd_even_markets" && (
                                    <>   
                                        <td>
                                            <input type="number"autoComplete="off" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.runNo} onChange={(e) => handleInputChange('runNo', e.target.value)}  placeholder='runNo'/>
                                        </td>
                                        <td>
                                            <input type="number" autoComplete="off" name="runYes" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.runYes} onChange={(e) => handleInputChange('runYes', e.target.value)} placeholder='runYes'/>
                                        </td>
                                        <td>
                                            <input type="number" autoComplete="off" name="oddsNo" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.oddsNo} onChange={(e) => handleInputChange('oddsNo', e.target.value)} placeholder='oddsNo'/>
                                        </td>
                                        <td>
                                            <input type="number" autoComplete="off" name="oddsYes" className="fancy-input" onKeyDown={handleKeyDown} value={newOdds.oddsYes} onChange={(e) => handleInputChange('oddsYes', e.target.value)} placeholder='oddsYes'/>
                                        </td>
                                    </>
                                )
                            )}

                            <td className="toggle-password">
                                <ToogleButton defaultChecked={newOdds.suspend === 1} value={newOdds.suspend} onChange={(e) => handleInputChange('suspend', e.target.value)}/>
                            </td>

                            <td>
                                <div className="toggle-password">
                                    <div className='actions_tab'>
                                        <div className='fancy-submit'>
                                            <MdPostAdd className="icon" onClick={handleSubmit}/>
                                        </div>
                                        
                                        <div className='fancy-cancel'>
                                            <MdDelete className="icon" onClick={onRemoveOdds}  />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </>
    );
};

export default FancyAssignAddOdds;



