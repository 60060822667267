import moment from 'moment';


function identifyFormat(dateString) {
    const possibleFormats = [
      "YYYY-MM-DD",
      "YYYY/MM/DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-MM-DD HH:mm:ss",
      "YYYY/MM/DD HH:mm:ss",
      "MM/DD/YYYY HH:mm:ss",
      "DD/MM/YYYY HH:mm:ss",
      "YYYY-MM-DDTHH:mm:ssZ", // ISO 8601
      "YYYY-MM-DDTHH:mm:ss.SSSZ" // ISO 8601 with milliseconds
    ];
  
    for (const format of possibleFormats) {
      if (moment(dateString, format, true).isValid()) {
        return format;
      }
    }
  
    return "YYYY-MM-DD HH:mm:ss";
}


function newTimestampConverterForFormat(utcTimestamp, format) {
    let timestampFormat = identifyFormat(utcTimestamp);
    return moment.utc(utcTimestamp, timestampFormat) // Parse as UTC
            .tz(moment.tz.guess()) // Convert to local timezone
            .format(format); // Format the output
}

export default newTimestampConverterForFormat