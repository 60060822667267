import "../addEvent/addevent.css";
import { MdDelete } from "react-icons/md";
import React, { useState, useEffect } from "react";
import MarketSettingsTab from "./MarketSettingsTab";
import { postCaller, getCaller, deleteCaller } from "../../services/api";
import { showToast, showSuccessMessage, showConfirmationDialog, showDeleteMessage } from "../../utilities/toastHelper";
import Pagination from "../../component/pagination/Pagination";

const OddsGap = () => {
    const [oddsYes, setOddsYes] = useState('');
    const [oddsNo, setOddsNo] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [gapList, setGapList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [fetchedPages, setFetchedPages] = useState({});
    const limit = 50;

    const fetchOddsGapList = async (page = 1) => {
        if (page > 1&&fetchedPages[page]) {
            setGapList(fetchedPages[page]);
            return;
        }
        try {
            const response = await getCaller(`api/v1/settings/alloddGapSettings?page=${page}&limit=${limit}`);
            if (response.success) {
                setGapList(response.data || []);
                setTotalPage(response?.pagination?.totalPages);
                setFetchedPages((prev) => ({ ...prev, [page]: response.data }));
            }
        } catch (error) {
            showToast("Failed to fetch odds gap list", true);
        }
    };

    useEffect(() => {
        fetchOddsGapList();
    }, []);

    useEffect(() => {
        setIsButtonEnabled(oddsYes !== '' && oddsNo !== '');
    }, [oddsYes, oddsNo]);

    const handleSubmit = async () => {
        const oddsGapData = { odds_yes: oddsYes, odds_no: oddsNo };

        try {
            const eventResponse = await postCaller(`api/v1/settings/addOddGapSettings`, oddsGapData);
            if (eventResponse) {
                showSuccessMessage("Odds Gap has been added successfully.");
                setOddsYes('');
                setOddsNo('');
                fetchOddsGapList();
            }
        } catch (error) {
            showToast("Failed to add odds gap", true);
        }
    };

    const handleDelete = async (id) => {
        const result = await showConfirmationDialog("You want to delete this odds gap setting?");
        if (result.isConfirmed) {
            try {
                const deleteResponse = await deleteCaller(`api/v1/settings/deleteOddsSettings?id=${id}`);
                if (deleteResponse && deleteResponse.success) {
                    showDeleteMessage("Odds Gap setting deleted successfully.");
                    fetchOddsGapList();
                }
            } catch (error) {
                showToast("Failed to delete odds gap setting", true);
            }
        }
    };

    const filteredGapList = gapList.filter(gap =>
        (gap.odds_no.toString().includes(searchQuery) || gap.odds_yes.toString().includes(searchQuery))
    );

    const handlePageChange = async (page) => {
        if (page >= 1 && page <= totalPage) {
            setCurrentPage(page);
            await fetchOddsGapList(page);
        }
    };
    
    return (
        <>
            <div className="wrapper">
                <MarketSettingsTab />
                <div className="wrapper-head" style={{ marginTop: "12px" }}>
                    <h3> Add New Odds Gap </h3>
                </div>

                <div className="input-container market-count" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <input
                        type="text"
                        inputMode="numeric"
                        placeholder="Enter Odds No"
                        className="market-count-input"
                        value={oddsNo}
                        onChange={(e) => setOddsNo(e.target.value.replace(/[^0-9]/g, ''))}
                    />
                    <span style={{ fontSize: "24px", margin: "0px", verticalAlign: "middle" }}>:</span>
                    <input 
                        type="text"
                        inputMode="numeric"
                        placeholder="Enter Odds Yes"
                        className="market-count-input"
                        value={oddsYes}
                        onChange={(e) => setOddsYes(e.target.value.replace(/[^0-9]/g, ''))}
                    />
                    <button 
                        className="update-league-btn centerBtn" 
                        type="button" 
                        style={{ marginLeft: '0px' }} 
                        onClick={handleSubmit}
                        disabled={!isButtonEnabled}
                    >
                        Save
                    </button>
                </div>

                <div className="table-border">
                    <div className="wrapper-body">
                        <div className="wrapper-content" style={{ width: "100%" }}>
                            <h2>Odds Gap List</h2>
                            <input 
                                type="search" 
                                autoComplete="off" 
                                placeholder='Search Odds' 
                                className='result-input' 
                                style={{ width: '20%' }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div>
                    {filteredGapList.length === 0 ? (
                        <div className="no-data">No List Found</div>
                    ) : (
                        <table className="odds-gap-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Odds No</th>
                                    <th>Odds Yes</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGapList.map((gap, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                        <td>{gap.odds_no}</td>
                                        <td>{gap.odds_yes}</td>
                                        <td>
                                            <div className="user-btn-action">
                                                <button className="edit-btn delete-btn" onClick={() => handleDelete(gap.id)}>
                                                    <MdDelete style={{ fontSize: '1.5rem', color: 'white' }}/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
            </div>
        </>
    );
};

export default OddsGap;