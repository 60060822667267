import React from 'react';
import './result.css';
import { useLocation, useNavigate } from 'react-router-dom';


const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
 
  const results = [
    {name:"Sport Result", route:"/results/match_result" },
    {name:"Worli Matka Result", route:"/results/worli-matka-result/match_result"},
    {name:"Racing Result", route:"/results/racing"},
  ]

  const sportResultRoutes = [
    "/results/match_result",
    "/results/fancy_result",
    "/results/bookmaker_result",
    "/results/pending_match",
    "/results/pending_fancy",
    "/results/pending_bookmaker"
  ];

  const worliMatkaRoutes = [
    "/results/worli-matka-result/match_result",
    "/results/worli-matka-result/pending_match"
  ];

  return (
    <div className="container">
      <ul className="tab-container">
        {results.map((el, i) => {
          const isActive =
          location.pathname.startsWith(el.route) ||
          (el.name === "Sport Result" && sportResultRoutes.includes(location.pathname)) ||
          (el.name === "Worli Matka Result" && worliMatkaRoutes.includes(location.pathname));

          return (
            <li key={i} className={`tab-box ${isActive ? 'active-result-tab' : null}`} onClick={() => navigate(el.route)}> {el.name}</li>
          );
        })}
      </ul>
    </div>
  );
}

export default Result